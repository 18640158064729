<template>

  <div v-if="isModalDisplayed" class="modal">

    <div class="modal-content">

      <input
        type="text"
        class="form-control"
        placeholder="Entrez votre pseudo ici"
        maxlength="15"
        v-model.trim="pseudo"
        @input="activeButton"
        @keypress.enter="registerPlayer(pseudo)"
      />

    </div>

    <span class="errorMes" v-if="errorMessage">{{ errorMessage }}</span>

    <button
      class="modal-btn"
      :disabled="isDisabled"
      @click="registerPlayer(pseudo)"
    >
       Rejoindre le Lobby
    </button>

  </div>

  <div v-else class="online-player-card-wrap">

    <div class="online-players-card">

      <div class="player-card-header">

        <h3>Players Online</h3>

        <span class="player-online-count">{{ players.length }}</span>

      </div>

      <div class="player-card-body">

        <div v-for="player in players" :key="player.id">

          <Player
            @on-challenge="onChallenge"
            :socket="socket"
            :player="player"
          />

        </div>

      </div>

    </div>

    <div v-if="defyBy !== null" class="common-popup">

      <div class="popup-inner">

        <h3>Oseras tu m'affronter ?</h3>

        <h2>{{ defyBy.pseudo }}</h2>

        <span>vous défie</span>

        <div class="challenged-btns btn-group">

          <button @click="acceptGame" class="button accept-btn">Jouer</button>

          <button @click="rejectGame" class="button reject-btn">
             Pas maintenant
          </button>

        </div>

      </div>

    </div>

  </div>

</template>

<script>
import io from "socket.io-client";
import Player from "./ThePlayer";

export default {
  name: "ThePlayers",
  components: {
    Player,
  },
  data() {
    return {
      players: [],
      pseudo: "",
      defyBy: null,
      isModalDisplayed: true,
      errorMessage: "",
      isDisabled: true,
      socket: io(process.env.VUE_APP_WS),
    };
  },
  methods: {
    activeButton() {
      if (this.errorMessage != "") this.errorMessage = "";
      return (this.isDisabled = false);
    },
    getPlayerByID(id) {
      return this.players.find((player) => player.id === id);
    },
    onChallenge(defyId) {
      this.socket.emit("challenge", defyId);
    },
    acceptGame() {
      const player = this.getPlayerByID(this.socket.id);
      this.socket.emit("accepted", { player, opponent: this.defyBy });
    },
    rejectGame() {
      this.socket.emit("rejected", this.defyBy.id);
      this.defyBy = null;
    },
    registerPlayer(pseudo) {
      if (pseudo !== "") {
        this.socket.emit("join", { pseudo: pseudo, room: "lobby" });
      } else {
        this.errorMessage = "Vous devez renseigner un pseudo";
      }
    },
    reConnectPlayerToLobby(pseudo, symbol) {
      this.isModalDisplayed = false;
      this.socket.emit("join", {
        pseudo: pseudo,
        room: "lobby",
        symbol: symbol,
      });
    },
  },
  created() {
    if (this.$route.params.pseudo !== undefined) {
      this.reConnectPlayerToLobby(
        this.$route.params.pseudo,
        this.$route.params.symbol
      );
    }
  },
  mounted() {
    /**
     * Ecouteurs d'évènements du Web Socket
     * Mise à jour de la liste des joueurs qd qqun rejoint ou quitte
     */
    this.socket.on("pseudoExist", (pseudoExist) => {
      if (!pseudoExist) {
        this.isModalDisplayed = false;
      } else {
        this.errorMessage = "Pseudo déjà utilisé !";
      }
    });
    this.socket.off("user_joined");
    this.socket.on("user_joined", (users) => {
      users.map((user) => (user.isCurrentUser = user.id === this.socket.id));
      this.players = users;
    });
    this.socket.off("user_left");
    this.socket.on("user_left", (users) => {
      users.map((user) => (user.isCurrentUser = user.id === this.socket.id));
      this.players = users;
    });
    this.socket.off("accept");
    this.socket.on("accept", (reqOpponentID) => {
      const reqFromPlayer = this.getPlayerByID(reqOpponentID);
      if (reqFromPlayer) this.defyBy = reqFromPlayer;
    });
    this.socket.off("accepted");
    this.socket.on("accepted", ({ matchID }) => {
      const player = this.getPlayerByID(this.socket.id).pseudo;
      const symbol = this.getPlayerByID(this.socket.id).symbol;
      //Redirect to game room
      //and user is disconnected from lobby automatically
      //can't be avoid, part of Socket.io mechanism :/
      this.$router.push({
        name: "Game",
        params: {
          player,
          symbol,
          match: matchID,
        },
      });
    });
  },
  beforeDestroy() {
    this.socket.emit("disconnect");
    this.socket.off();
    this.socket.disconnect();
  },
};
</script>

