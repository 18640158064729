<template>

  <div :class="['player-list', isCurrentPlayerClass, isPlayingClass]">

    <div class="player-thumbnail">

      <img :src="symbol" />

    </div>

    <div class="player-info">

      <div class="player-info-left">

        <h4>{{ player.pseudo }}</h4>

      </div>

      <button
        :disabled="player.isCurrentUser"
        @click="onChallenge"
        class="button"
      >
        {{ button }}
      </button>

    </div>

  </div>

</template>

<script>
export default {
  name: "ThePlayer",
  props: ["player", "socket"],

  data() {
    return {
      button: this.player.isCurrentUser ? "Vous !" : "Défier",
      isCurrentPlayerClass: `current-user-${this.player.isCurrentUser}`,
      isPlayingClass: `is-playing-${this.player.playing}`,
      symbol:
        this.player.symbol === "pionorc"
          ? require("@/assets/img/pionorc.png")
          : require("@/assets/img/pionhuman.png"),
    };
  },
  methods: {
    onChallenge() {
      if (this.player.isCurrentUser || this.player.playing) return;
      this.$emit("on-challenge", this.player.id);
      this.button = "En attente...";
    },
  },
  mounted() {
    /**
     * Surveille si l'adversaire refuse le défi
     */
    if (this.player.playing) {
      this.button = "En jeux !";
    }

    this.socket.on("rejected", (id) => {
      if (this.player.id === id) {
        this.button = "Refusé";
        setTimeout(() => {
          this.button = "Défier";
        }, 2000);
      }
    });
  },
};
</script>
