<template>

  <div id="players">

    <Players />

  </div>

</template>

<script>
import Players from "../components/ThePlayers";

export default {
  name: "TheHome",
  components: { Players },
};
</script>

